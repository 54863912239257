<template>
  <div>
    <iframe class="privacy-box" :src="iframeUrl" ref="myIframe"></iframe>
  </div>
  <!-- <div class="textBox">{{ Text }}</div> -->
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_1.png";
import importedImg2 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_2.png";
import importedImg3 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_3.png";
import importedImg4 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_4.png";
import importedImg5 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_5.png";
// import importedImg6 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_6.png";
// import importedImg7 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_7.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      altText: "图片",
      iframeUrl:
        "https://sup.zedfinancewalletkop.com/Z0lE3Dj0u/g4Gc8Kp2a4.html",
      Text: `เรียนคุณลูกค้า บริษัทของเราคือบริษัท CLOUDZED COMPANY LIMITED และเราขอขอบคุณที่ใช้แอปพลิเคชัน ZED Wallet ในฐานะผู้พัฒนา ZED Wallet เราตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลที่มีต่อคุณ เราให้ความสำคัญกับการปกป้องความเป็นส่วนตัวของคุณเป็นอย่างมาก และมีหน้าที่รับผิดชอบในการจัดการและปกป้องข้อมูลส่วนบุคคลของคุณอย่างเหมาะสม เราจัดให้มีคำชี้แจงความเป็นส่วนตัวนี้ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลของประเทศไทย

ด้วยนโยบายความเป็นส่วนตัวนี้ เราจะแนะนำประเภทของข้อมูลที่เราอาจรวบรวมเกี่ยวกับผู้ใช้ ตลอดจนวัตถุประสงค์และวิธีการในการรวบรวมข้อมูลดังกล่าว นอกจากนี้เรายังจะอธิบายพื้นฐานทางกฎหมายสำหรับการรวบรวมข้อมูล (ถ้ามี) ระยะเวลาการเก็บรักษาข้อมูล สิทธิ์ของผู้ใช้ และวิธีการติดต่อเรา ในทำนองเดียวกัน เราจะอธิบายว่าข้อมูลที่ได้รับมีการเข้าถึง รวบรวม ใช้และแบ่งปันอย่างไร เพื่อให้มั่นใจว่าข้อมูลจะถูกนำไปใช้ตามวัตถุประสงค์เฉพาะที่ระบุไว้เท่านั้น

เราต้องการชี้แจงนโยบายความเป็นส่วนตัวนี้และเตือนคุณถึงความสำคัญของการอ่านและทำความเข้าใจเอกสารนี้อย่างรอบคอบก่อนที่จะใช้บริการของ ZED Wallet เพื่อให้คุณสามารถตัดสินใจได้อย่างมีข้อมูล

เอกสารนโยบายความเป็นส่วนตัวนี้ครอบคลุมประเด็นต่อไปนี้:

1. ข้อมูลที่เรารวบรวม:
◆ เราจะให้รายละเอียดเกี่ยวกับประเภทของข้อมูลที่เรารวบรวมจากผู้ใช้ของเรา
2. การใช้ข้อมูลที่รวบรวม:
◆ เราจะอธิบายว่าเราใช้ข้อมูลที่เรารวบรวมอย่างไรและเพื่อวัตถุประสงค์อะไร
3. การจัดเก็บและการปกป้องข้อมูลส่วนบุคคลของคุณ:
◆ เราจะอธิบายขั้นตอนและมาตรการที่เรามีเพื่อจัดเก็บและปกป้องข้อมูลส่วนบุคคลของคุณ
4. สิทธิ์ของผู้ใช้:
◆ เราจะแสดงรายการสิทธิ์ที่ผู้ใช้มีเกี่ยวกับข้อมูลส่วนบุคคลของพวกเขาและวิธีการใช้สิทธิ์เหล่านี้
5. การคุ้มครองข้อมูลส่วนบุคคลของผู้เยาว์:
◆ เราจะอธิบายมาตรการพิเศษที่เราใช้เพื่อปกป้องข้อมูลส่วนบุคคลของผู้ใช้ผู้เยาว์
6. การเปลี่ยนแปลงนโยบายนี้:
◆ เราจะแจ้งให้คุณทราบว่าเราเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้อย่างไรและเมื่อใด และจะแจ้งการเปลี่ยนแปลงดังกล่าวอย่างไร
7. ติดต่อเรา:
◆ เราจะให้ข้อมูลการติดต่อสำหรับคำถาม คำขอ หรือข้อกังวลที่เกี่ยวข้องกับความเป็นส่วนตัวของผู้ใช้
โครงสร้างนี้ช่วยให้แน่ใจว่าเราตระหนักดีถึงหลักปฏิบัติของเราเกี่ยวกับความเป็นส่วนตัวของผู้ใช้ หากคุณมีคำถามหรือข้อกังวลเกี่ยวกับประเด็นข้างต้นใดๆ โปรดติดต่อเรา
ชื่อบริษัท : CLOUDZED COMPANY LIMITED (ต่อไปนี้จะเรียกว่า “บริษัท”)
อีเมล์: contact@cloudzedcompany.com
ที่อยู่บริษัท: 19/125 Sukhumvit Road Soi Sukhumvit 13 (Saengchan) 13 Floor 10110 VADHANA

1. ข้อมูลที่เรารวบรวม

(1) อุปกรณ์เคลื่อนที่:
ZED Wallet ขอสงวนสิทธิ์ในการรวบรวมและตรวจสอบข้อมูลบางอย่างเกี่ยวกับอุปกรณ์ของคุณ เช่น ชื่อ รุ่น ฮาร์ดแวร์ ซอฟต์แวร์ IMEI และหมายเลขซีเรียล จุดประสงค์คือเพื่อระบุอุปกรณ์ของคุณโดยไม่ซ้ำกันและป้องกันการฉ้อโกง
ข้อมูลจะถูกเข้ารหัสและส่งไปยังเซิร์ฟเวอร์ ZED Wallet (www.cloudzedcompany.com) เพื่อวัตถุประสงค์ในการประเมินเครดิตและการต่อต้านการฉ้อโกงโดยเฉพาะ ZED Wallet จะไม่ทำการตลาดหรือให้เช่าข้อมูลอุปกรณ์ของคุณแก่บุคคลที่สาม

(2) สิทธิ์การเข้าถึง SMS:
ZED Wallet จะขออนุญาตในการเข้าถึงข้อความ รวมถึงเนื้อหา ผู้ส่ง และสถานะการอ่าน แอปพลิเคชันนี้ได้รับการออกแบบเพื่อสร้างโปรไฟล์ทางสังคมและการเงิน ตลอดจนระบุและวิเคราะห์รูปแบบพฤติกรรมและความเสี่ยงที่เกี่ยวข้องกับสินเชื่อหลายรายการ การย้ายดังกล่าวจะสนับสนุนการประเมินเครดิตและการป้องกันการฉ้อโกง เฉพาะข้อความทางการเงิน (ข้อความ 6 หลัก) เท่านั้นที่จะถูกตรวจสอบ ข้อมูล SMS จะถูกส่งอย่างปลอดภัยผ่าน SSL ไปยังเซิร์ฟเวอร์ของ www.cloudzedcompany.com ข้อมูลนี้จะนำไปใช้สำหรับการบริหารความเสี่ยงและการควบคุมการต่อต้านการฉ้อโกงเท่านั้น ด้วยความยินยอมของคุณ จะไม่สามารถแบ่งปันกับบุคคลที่สามโดยไม่ได้รับการอนุมัติจากคุณ

(3) สิทธิ์บันทึกการโทร:
เพื่อประเมินความเสี่ยงด้านเครดิตของคุณ แอปของเราต้องเข้าถึงข้อมูลประวัติการโทรของคุณ ข้อมูลทั้งหมดใช้เพื่อวัตถุประสงค์ในการตรวจสอบตัวตนและการประเมินเครดิตเท่านั้น มีการเข้ารหัสอย่างเคร่งครัดและจะไม่ถูกแชร์กับบุคคลที่สาม คุณสามารถจัดการหรือเพิกถอนการอนุญาตนี้ได้ตลอดเวลาในการตั้งค่า แต่อาจส่งผลต่อการใช้บริการบางอย่าง

(4) ข้อมูลตัวตน:
แอป ZED Wallet จะรวบรวมรายละเอียดตัวตนที่สมบูรณ์ เช่น ชื่อนามสกุล ที่อยู่ หมายเลขติดต่อ สถานภาพการสมรส เพศ ประเทศ วันที่และสถานที่เกิด อายุ CURP อีเมล ฯลฯ ข้อมูลนี้จะถูกเข้ารหัสและอัปโหลดไปยังเซิร์ฟเวอร์ www.cloudzedcompany.com เพื่อการตรวจสอบสิทธิ์และการป้องกันการฉ้อโกงโดยเฉพาะ ZED Wallet จะไม่เปิดเผยหรือทำการตลาดข้อมูลของคุณกับบุคคลที่สามโดยไม่ได้รับอนุญาตจากคุณ

(5) ตำแหน่งโดยประมาณ:
แอป ZED Wallet จะตรวจสอบตำแหน่งที่กระจัดกระจายของการใช้งานอุปกรณ์ของคุณ และได้รับการออกแบบมาเพื่อลดความเสี่ยงของการฉ้อโกงที่เกี่ยวข้องกับการสมัครขอสินเชื่อ ตำแหน่งจะถูกเข้ารหัสและข้อมูลจะถูกส่งอย่างปลอดภัยระหว่างอุปกรณ์ของคุณและเซิร์ฟเวอร์ ZED Wallet (www.cloudzedcompany.com) โดยเฉพาะสำหรับการประเมินเครดิตและการป้องกันการฉ้อโกง ZED Wallet ไม่ทำการตลาดหรือถ่ายโอนข้อมูลตำแหน่งไปยังบุคคลที่สาม

(6) กล้อง:
ZED Wallet จะขอเข้าถึงกล้องของคุณเพื่อให้คุณสามารถสแกนเอกสาร KYC ที่จำเป็นสำหรับการสมัครสินเชื่อของคุณได้อย่างง่ายดาย

(7) ปฏิทิน:
เราขอเข้าถึงปฏิทินของคุณเพื่อให้บริการแก่คุณ เช่น การแจ้งเตือนการชำระคืน และช่วยให้คุณจัดการแผนการชำระหนี้ได้ทันท่วงที เราไม่รวบรวมหรือจัดเก็บข้อมูลที่เกี่ยวข้องกับปฏิทินใด ๆ หรือใช้เพื่อวัตถุประสงค์อื่นหรือแบ่งปันกับบุคคลที่สาม คุณสามารถเพิกถอนการอนุญาตนี้ได้ตลอดเวลาในการตั้งค่าอุปกรณ์ของคุณ

2. การใช้ข้อมูลที่รวบรวม:

(1) ข้อมูลส่วนบุคคลที่รวบรวมโดย ZED Wallet จะถูกนำมาใช้เพื่อยืนยันตัวตนของคุณในการวิเคราะห์ความเสี่ยง การประเมินความสามารถในการละลาย และการป้องกันและตรวจจับกิจกรรมการฉ้อโกง
(2) วิเคราะห์ข้อมูลที่ผู้ใช้ให้ไว้เพื่อกำหนดระดับความเสี่ยง ความสามารถในการจ่าย เงื่อนไขการดำเนินงาน และการประเมินอื่น ๆ ที่จำเป็นภายใต้กฎหมายและข้อบังคับในปัจจุบัน เพื่อให้บริการตามกฎหมายและข้อบังคับในปัจจุบัน
(3) เราใช้ข้อมูลของคุณเพื่อปรับปรุงบริการ เพิ่มพูนประสบการณ์และการโต้ตอบกับ ZED Wallet และแจ้งให้คุณทราบเกี่ยวกับการอัปเดตบริการ
(4) ปรับแต่งประสบการณ์ของคุณเพื่อมอบประเภทเนื้อหาและผลิตภัณฑ์ที่คุณสนใจมากที่สุด
(5) ปกป้องสิทธิ์ของเรา รวมถึงโดยการส่งการแจ้งเตือนอย่างทันท่วงทีเพื่อกู้คืนงานที่ค้างชำระ การจัดการรายการผิดนัด และการเก็บรักษาข้อมูลที่สามารถใช้เป็นหลักฐานสำหรับข้อพิพาทและการตรวจสอบ
(6) สื่อสารกับคุณและแจ้งให้คุณทราบถึงการเปลี่ยนแปลงข้อกำหนดผลิตภัณฑ์หรือบริการของเรา

3. การจัดเก็บและการปกป้องข้อมูลส่วนบุคคลของคุณ:

(1) เพื่อให้มั่นใจในความปลอดภัยของข้อมูลของคุณ เราจะใช้มาตรการที่เหมาะสมและจำเป็นทั้งหมดหลังจากการรวบรวมข้อมูลของคุณ ข้อมูลส่วนบุคคลจะถูกรวบรวมและเข้ารหัสก่อนที่จะอัปโหลดไปยัง www.cloudzedcompany.com ข้อมูลทั้งหมดที่ส่งผ่านบริการของเราจะถูกเข้ารหัสระหว่างการส่งและขณะพัก ตัวอย่างเช่น เราใช้ Transport Layer Security (TLS) เพื่อเข้ารหัสข้อความและรูปภาพระหว่างทาง
(2) นอกจากนี้ เรายังกำหนดการควบคุมการเข้าถึงด้านเทคนิคและการบริหารเพื่อจำกัดการเข้าถึงข้อมูลส่วนบุคคลที่ไม่เปิดเผยต่อสาธารณะแก่พนักงานและผู้รับเหมาของเรา
(3) เมื่อคุณตัดสินใจที่จะหยุดใช้แอปพลิเคชันของเรา เราจะหยุดการรวบรวมและใช้ข้อมูลของคุณ เว้นแต่กฎหมายและข้อบังคับจะกำหนดไว้เป็นอย่างอื่น หากการดำเนินการยุติลง เราจะหยุดรวบรวมข้อมูลส่วนบุคคลของคุณทันทีและแจ้งให้คุณทราบโดยการประกาศ ข้อมูลส่วนบุคคลใด ๆ ที่เก็บไว้ในเว็บไซต์ของเราจะถูกลบโดยอัตโนมัติ

4. สิทธิ์ของผู้ใช้:

(1) การเข้าถึงข้อมูลส่วนบุคคลของคุณ: ภายใต้ข้อยกเว้นที่กำหนดโดยกฎหมายและข้อบังคับ คุณมีสิทธิ์ที่จะทราบว่าเรามีข้อมูลส่วนบุคคลใดบ้างเกี่ยวกับคุณ วัตถุประสงค์ที่เราใช้ข้อมูลนี้ และเงื่อนไขการใช้งานที่เกี่ยวข้อง
(2) การแก้ไขข้อมูลส่วนบุคคลของคุณ: หากข้อมูลส่วนบุคคลของคุณล้าสมัย ไม่ถูกต้องหรือไม่สมบูรณ์ คุณมีสิทธิ์ขอแก้ไขได้ หากเราพิจารณาว่าข้อมูลนั้นไม่ได้ถูกใช้ตามหลักการและความรับผิดชอบที่กำหนดไว้ เราจะดำเนินการลบข้อมูลนั้นออกจากบันทึกหรือฐานข้อมูลของเรา
(3) ลบข้อมูลส่วนบุคคลของคุณ: คุณสามารถขอให้เราลบข้อมูลส่วนบุคคลของคุณภายใต้สถานการณ์ต่าง ๆ เช่น การยกเลิกการดำเนินงานและบริการ การหมดอายุของระยะเวลาการเก็บรักษา หรือการยกเลิกบัญชีของคุณ คุณสามารถติดต่อฝ่ายบริการลูกค้าของเรา (ข้อมูลติดต่อ: contact@cloudzedcompany.com) หลังจากได้รับคำขอของคุณ เราจะตรวจสอบและดำเนินการให้เสร็จสิ้นภายใน 15 วัน และลบข้อมูลของคุณออกจากบริการของเรา
(4) ยกเลิกบัญชี: คุณสามารถติดต่อฝ่ายบริการลูกค้าของเราได้ตลอดเวลาเพื่อขอยกเลิกบัญชีของคุณ (ข้อมูลติดต่อ: contact@cloudzedcompany.com) อย่างไรก็ตาม หากคุณมีเรื่องที่ค้างอยู่ การยกเลิกบัญชีอาจมีข้อจำกัดบางประการ เมื่อบัญชีของคุณถูกยกเลิก เราจะหยุดให้บริการแก่คุณ
(5) เรื่องอื่น ๆ: หากคุณประสบปัญหาในการเข้าถึง แก้ไข ลบ ยกเลิกหรือยกเลิกบัญชีของคุณด้วยวิธีการข้างต้น หรือคุณมีคำถามเกี่ยวกับการรวบรวมและการใช้ข้อมูลของคุณ คุณสามารถส่งอีเมลไปที่ฝ่ายบริการลูกค้าของเราได้ : contact@cloudzedcompany.com. เราพร้อมให้ความช่วยเหลือและตอบคำถามของคุณ

5. การคุ้มครองข้อมูลส่วนบุคคลของผู้เยาว์:

ZED Wallet ให้ความสำคัญอย่างยิ่งต่อการปกป้องข้อมูลส่วนบุคคลของผู้เยาว์ เว็บไซต์และบริการของเรามีไว้สำหรับผู้ใหญ่เท่านั้น เราจะใช้หมายเลข ID ที่รวบรวมในระหว่างกระบวนการตรวจสอบชื่อจริงเพื่อตรวจสอบอายุของผู้ใช้ หากผู้ใช้มีอายุต่ำกว่า 18 ปี เราขอให้ผู้ใช้ไม่ใช้ผลิตภัณฑ์/บริการที่เรามีให้
หากคุณไม่ได้รับความยินยอมจากผู้ปกครองหรือผู้ปกครองของคุณไม่ตกลงที่จะใช้บริการของเราและให้ข้อมูล เราขอแนะนำให้คุณหยุดใช้บริการของเราทันทีและติดต่อเราโดยเร็วที่สุด หากเราพบว่าเราได้เก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์ที่มีอายุต่ำกว่า 18 ปีโดยไม่ได้รับความยินยอมจากผู้ปกครองล่วงหน้า เราจะหยุดการรวบรวมและใช้ข้อมูลที่เกี่ยวข้องทันทีและลบข้อมูลที่เกี่ยวข้องทันที

6. เราจะตรวจสอบและอัปเดตนโยบายนี้อย่างไร:

นโยบายความเป็นส่วนตัวนี้จะได้รับการอัปเดตเมื่อธุรกิจของเราพัฒนาขึ้นเพื่อให้บริการที่ดีขึ้นแก่คุณ เราจะเผยแพร่เวอร์ชันอัปเดตบนเว็บไซต์ แอปพลิเคชัน หรือแพลตฟอร์มอื่น ๆ และแจ้งให้คุณทราบผ่านโฆษณา ข้อความเว็บไซต์ ป๊อปอัป หรือวิธีการอื่น ๆ ที่เหมาะสม หลังจากที่เนื้อหาที่เกี่ยวข้องที่ได้รับการอัปเดตมีผลบังคับใช้ เราขอแนะนำให้คุณเยี่ยมชมเป็นประจำเพื่อเรียนรู้เกี่ยวกับนโยบายความเป็นส่วนตัวล่าสุด

หากคุณไม่เห็นด้วยกับการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้ เราขอให้คุณหยุดใช้ผลิตภัณฑ์หรือบริการของ ZED Wallet การใช้ผลิตภัณฑ์หรือบริการของเราต่อไป แสดงว่าคุณตกลงที่จะผูกพันตามนโยบายความเป็นส่วนตัวฉบับปรับปรุง

7. ติดต่อเรา:
หากคุณมีคำถาม ความคิดเห็น หรือข้อเสนอแนะเกี่ยวกับนโยบายความเป็นส่วนตัวนี้ หรือต้องการคำอธิบายโดยละเอียดของข้อกำหนดที่มีอยู่ในนโยบายความเป็นส่วนตัวนี้ หรือพบปัญหาใด ๆ เมื่อใช้บริการที่เรามีให้ โปรดติดต่อเรา
เรามุ่งมั่นที่จะตรวจสอบคำถามของคุณโดยเร็วที่สุด เราแต่งตั้งบุคคลเฉพาะเพื่อทำกระบวนการยืนยันให้เสร็จสิ้นภายใน 15 วันหลังจากยืนยันตัวตนผู้ใช้ของคุณ ความคิดเห็นของคุณมีค่าสำหรับเรา และเราพร้อมให้ความช่วยเหลือ

ติดต่อเรา:
ชื่อบริษัทหรือ APP: CLOUDZED COMPANY LIMITED/ZED Wallet
อีเมลฝ่ายบริการลูกค้า: contact@cloudzedcompany.com
เวลาทำการของเรา: 9.00 น. ถึง 18.00 น.
ติดต่อเรา: +66 6783296201`,
    };
  },
  created() {
    this.gotoTop();
    // this.refreshIframe();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    refreshIframe() {
      // 为了刷新iframe，你可以添加一个时间戳参数到URL
      this.iframeUrl = this.iframeUrl + "?timestamp=" + new Date().getTime();
    },
    refreshIframe2() {
      if (this.$refs.myIframe) {
        setTimeout(() => {
          this.$refs.myIframe.contentWindow.location.reload(true);//页面重新加载

        }, 200);
      }
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}

.img5Box {
  margin-top: 50px;
}
.textBox {
  color: #333333;
  white-space: pre-wrap;
}
.privacy-box {
  width: 100%;
  height: 100vh;
  border: 0;
}
</style>
